import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backend_url } from '../libs/data';

// Add to stems cart
export const addToStemsCart = createAsyncThunk(
  'stemsCart/addToStemsCart',
  async ({ userId, beatId, isLoggedIn }, { rejectWithValue }) => {
    if (isLoggedIn) {
      try {
        await axios.post(`${backend_url}/api/stemscart`, { userId, beatId });
        return beatId;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      return beatId;
    }
  }
);

// Remove from stems cart
export const removeFromStemsCart = createAsyncThunk(
  'stemsCart/removeFromStemsCart',
  async ({ userId, beatId, isLoggedIn }, { rejectWithValue }) => {
    if (isLoggedIn) {
      try {
        await axios.delete(`${backend_url}/api/stemscart`, { data: { userId, beatId } });
        return beatId;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      return beatId;
    }
  }
);

// Fetch stems cart items
export const fetchStemsCartItems = createAsyncThunk(
  'stemsCart/fetchStemsCartItems',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${backend_url}/api/stemscart/${userId}`);
      return response.data.cartItems;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stemsCartSlice = createSlice({
  name: 'stemsCart',
  initialState: {
    cartItems: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStemsCartItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStemsCartItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cartItems = action.payload;
      })
      .addCase(fetchStemsCartItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addToStemsCart.fulfilled, (state, action) => {
        if (!state.cartItems.some((item) => item._id === action.payload)) {
          state.cartItems.push({ _id: action.payload });
        }
      })
      .addCase(removeFromStemsCart.fulfilled, (state, action) => {
        state.cartItems = state.cartItems.filter((item) => item._id !== action.payload);
      })
      .addCase(addToStemsCart.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(removeFromStemsCart.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default stemsCartSlice.reducer;
