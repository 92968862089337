import { createSlice } from '@reduxjs/toolkit';
import { backend_url } from '../libs/data';

const initialState = {
    token: localStorage.getItem('token') || null,
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    user:null
  };
  

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const {token,user} = action.payload;
      state.token = token;
      state.user = user
      state.isAuthenticated = true;
      localStorage.setItem('token', token);
      localStorage.setItem('isAuthenticated', true);
    },
    signup: (state, action) => {
        const {token,user} = action.payload;
        state.token = token;
        state.user = user;
        state.isAuthenticated = true;
        localStorage.setItem('token', token);
        localStorage.setItem('isAuthenticated', true);
      },
    logout: (state) => {
      if (state.user && state.user.lastSessionDate) {
        const sessionEndTime = new Date();
        const sessionStartTime = new Date(state.user.lastSessionDate);
        const sessionDuration = (sessionEndTime - sessionStartTime) / 1000; 
    
        fetch(`${backend_url}/api/update-session-time`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: state.user._id,
            sessionDuration
          })
        });
      }
  
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.setItem('isAuthenticated', false);

    },
  },
});

export const { login, logout,signup } = authSlice.actions;

export default authSlice.reducer;
