import React from 'react'
import UserLayout from '../../layout/UserLayout'
import Cards from '../../components/Cards'
import musicIcon from '../../assets/musicIcon.svg'
import Section from '../../components/Section';

const MusicItem = ({ number, name, artist, bpm, tags }) => {
    return (
        <div className="flex py-2 items-center justify-between text-white rounded-lg">
            <div className="grid lg:grid-cols-12 lg:bg-black bg-[#141414] grid-cols-1 rounded-lg w-full items-center">
                <div className="text-lg lg:col-span-1 py-2 md:bg-[#141414] lg:h-20 lg:w-[4vw] justify-center rounded-lg flex flex-col items-center font-bold">{number}</div>
                <div className="lg:flex-row flex-col flex justify-center md:justify-between gap-4 items-center w-full bg-[#141414] py-4 px-4 rounded-lg lg:col-span-11">
                    <img src={musicIcon} alt="" className='h-10 w-10' />
                    <div className='flex items-center gap-3'>
                        <div className="font-bold">Name</div>
                        <div className='text-[15px]'>{name}</div>
                    </div>
                    <div className='flex gap-3'>
                        <div className="font-bold">Artist</div>
                        <div>{artist}</div>
                    </div>
                    <div className='flex gap-3'>
                        <div className="font-bold">BPM</div>
                        <div>{bpm}</div>
                    </div>
                    <div className='flex md:flex-row flex-col gap-3'>
                        <div className="font-bold text-center">Tags</div>
                        <div className="flex gap-2">
                            {tags.map((tag, index) => (
                                <span key={index} className="bg-black px-2 py-1 rounded-md text-sm">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md">
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BeatManagement = () => {
    const items = [
        { number: '01', name: 'blah blah', artist: 'Artist', bpm: 120, tags: ['#tag1', '#tag2', '#tag3'] },
        { number: '02', name: 'blah blah', artist: 'Artist', bpm: 120, tags: ['#tag1', '#tag2', '#tag3'] },
        { number: '03', name: 'blah blah', artist: 'Artist', bpm: 120, tags: ['#tag1', '#tag2', '#tag3'] },
        { number: '04', name: 'blah blah', artist: 'Artist', bpm: 120, tags: ['#tag1', '#tag2', '#tag3'] },
    ];
    return (
        <>
            <UserLayout>
                <Section>
                    <div className="px-5 md:px-14">
                        <div className="py-8">
                            <h1 className="text-2xl font-bold text-white">Beats Management</h1>
                        </div>
                        <div className="flex py-4  justify-between">
                            <h2 className="text-xl text-[#9F9F9F]">Leases</h2>
                            <button className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                See More
                            </button>
                        </div>
                        <div className="">
                            {items.map((item, index) => (
                                <MusicItem key={index} {...item} />
                            ))}
                        </div>
                        <div className="flex py-4 pt-8 justify-between">
                            <h2 className="text-xl text-[#9F9F9F]">Exclusive </h2>
                            <button className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                See More
                            </button>
                        </div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-5">
                            <Cards />
                            <Cards />
                            <Cards />
                            <Cards />
                        </div>


                    </div>
                </Section>
            </UserLayout>
        </>
    )
}

export default BeatManagement