// PasswordChangeWarning.js
import React from 'react';

const PasswordChangeWarning = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed top-4 right-4 max-w-sm w-full bg-red-100 border border-red-400 text-red-700 rounded-lg shadow-lg p-4">
      <div className="flex items-center">
        <svg
          className="w-5 h-5 mr-2 flex-shrink-0 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11.25 4.5a1.5 1.5 0 00-3 0v7.5a1.5 1.5 0 003 0V4.5zM21 12c0 4.627-3.373 8.5-7.5 8.5S6 16.627 6 12 9.373 3.5 13.5 3.5 21 7.373 21 12z"
          />
        </svg>
        <h2 className="text-lg font-medium">Password Change Required</h2>
      </div>
      <p className="mt-2">
        Please change your password to access the system.
      </p>
    
    </div>
  );
};

export default PasswordChangeWarning;
