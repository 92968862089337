import React from 'react';
import Loader from '../assets/Loader.svg';

export const LoaderComp = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img width={'100px'} src={Loader} alt="Loading..." />
    </div>
  );
};
