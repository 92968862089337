import React, { useCallback, useEffect, useState } from 'react';
import UserLayout from '../../layout/UserLayout';
import download from '../../assets/download.svg';
import musicIcon from '../../assets/musicIcon.svg'; // Adjust the path as necessary
import Section from '../../components/Section';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/userSlice';
import axios from 'axios';
import { backend_url } from '../../libs/data';
import { LoaderComp } from '../../UI/Loader';
import PricingModel from '../../components/PricingModel';
import { useNavigate } from 'react-router-dom';
import MusicPlayer from '../../components/MusicPlayer';



const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error('Invalid date:', dateString);
        return 'Invalid Date'; 
    }

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}
  
const PurchasedHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
  
    const [purchasedData, setPurchasedData] = useState({
      beats: [],
      stems: [],
    });
    const [loading, setLoading] = useState({
      beats: false,
      leases: false,
      stems: false
    });
    const [selectedBeat, setSelectedBeat] = useState(null);
    const [isOpen,setIsOpen] = useState(false)
    const [musicIndex, setMusicIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedType, setSelectedType] = useState('beats');
  
    const [isAllow,setIsAllow] = useState(false)
    const [isAllowStems,setIsAllowStems] = useState(false)
    const [loadingl,setLoadingl] = useState(false)
    const handleSelect = (index, beat, type) => {
      setSelectedIndex(index);
      setMusicIndex(index);
      setSelectedBeat(beat);
      setSelectedType(type);
    };
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    useEffect(() => {
      dispatch(fetchUser());
    }, [dispatch]);
  
    // Error handling helper
    const handleError = (error) => {
      return error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    };
  
    // Combine fetching beats, leases, and stems into one function
    const fetchPurchasedData = useCallback(async () => {
        if (!user?.email) return;
      
        setLoading({ beats: true, leases: true, stems: true });
        try {
          const [beatsRes, leasesRes, stemsRes] = await Promise.all([
            axios.post(`${backend_url}/api/getpurchasedbeats`, { email: user.email }).catch(() => null),
            axios.post(`${backend_url}/api/getpurchasedExclusivebeats`, { email: user.email }).catch(() => null),
            axios.post(`${backend_url}/api/getpurchasedstems`, { email: user.email }).catch(() => null),
          ]);
    
          const combinedData = [
            ...(beatsRes?.data?.beats || []).map((item) => ({ ...item })),
            ...(leasesRes?.data?.beats || []).map((item) => ({ ...item })),
          ];
      
          if (combinedData.length === 0) {
            if (beatsRes?.data?.beats?.length > 0) {
              combinedData.push(...beatsRes.data.beats);
            } else if (leasesRes?.data?.beats?.length > 0) {
              combinedData.push(...leasesRes.data.beats);
            }
          }
      
          // Update the state with the purchased data
          setPurchasedData({
            beats: combinedData.length > 0 ? combinedData : [],  
            stems: stemsRes?.data?.beats?.length > 0 ? stemsRes.data.beats : [],
          });
        } catch (error) {
          console.error(handleError(error));
        } finally {
          setLoading({ beats: false, leases: false, stems: false });
        }
      }, [user]);
      
  
    useEffect(() => {
      if (user?.email) {
        fetchPurchasedData();
      }
    }, [user, fetchPurchasedData]);

    const HandleClosePricing = () => {
        setIsOpen(false);
        setSelectedBeat(null);
    };
    const HandleDownload = (beat)=>{
        setIsOpen(true)
         setSelectedBeat(beat)
         
    
    }
    
    const DownloadStems = async(fileLink)=>{
        const link = document.createElement("a");
        link.href = fileLink;
        link.download = fileLink.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    console.log("bea",purchasedData.beats)
    return (
        <UserLayout>
            <Section>
                {
                    loading.beats ? (
                        <LoaderComp/>
                    ) : (

                        <div className="bg-black min-h-screen  text-white px-5 md:px-10 pb-10">
                        <p className=' py-2 text-3xl font-bold'>My Exclusive Beats</p>
                        {purchasedData && purchasedData.beats.map((purchase,index) => (
                         <div className={`flex flex-col gap-3`}>
                           <div className="text-start text-[#9F9F9F] text-base">
                               Purchased on: {formatDate(purchase.purchasedAt)}
                           </div>
                       
                           <div key={purchase._id} className="flex py-2 items-center justify-between text-white md:bg-black bg-[#141414] rounded-lg">
                               <div className="grid lg:grid-cols-12 grid-cols-1 w-full md:items-center">
                                   <div className="text-lg lg:col-span-1 py-2 lg:h-20 lg:w-[4vw] bg-[#141414] justify-center rounded-lg flex flex-col items-center font-bold">
                                       {index + 1}
                                   </div>
                       
                                   <div className={`lg:flex-row flex-col flex gap-[8%] items-center w-full duration-300 ${musicIndex===index ? 'bg-gray-800 scale-105' : 'bg-[#141414]'}  py-5 px-4 rounded-lg lg:col-span-11`}  onClick={()=>{
                            handleSelect(index,purchase,'beats')
                         }}>
                                       <img src={musicIcon} alt="Music Icon" className="h-10 w-10" />
                       
                                       <div className="flex items-center gap-3  min-w-0 lg:w-[10%]">
                                           
                                           <div className="text-[18px] ">{purchase.beatTitle}</div>
                                       </div>
                       
                                       <div className="flex gap-3 lg:w-[10%] min-w-0 ">
                                           
                                           <div>{purchase.bpm} BPM</div>
                                       </div>
                       
                                       <div className="flex md:flex-row flex-col gap-3 lg:w-[30%] min-w-0 items-center justify-start py-2">
                                           
                                           <div className="flex gap-2 w-full overflow-hidden">
                                               {purchase.tags.map(tag => (
                                                   <span key={tag} className="bg-black px-2 py-1 rounded-md text-sm truncate">
                                                       #{tag}
                                                   </span>
                                               ))}
                                           </div>
                                       </div>
                       
                                       <div className="flex items-center ">
                                           <button className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md" onClick={() => { HandleDownload(purchase) }}>
                                               <img src={download} alt="" />
                                           </button>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                       
                        ))}
                     
                        <p className=' py-2 text-3xl font-bold pt-10'>Purchased Stems</p>
                        <div className='w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10'>
                        {purchasedData && purchasedData.stems.map((purchase,index)=>{
                            return (
                                  <div className="flex flex-col items-center py-3 w-[100%]" key={index}>
                                  <div className="bg-[#141414] rounded-xl py-5 w-full">
                                      <div className="text-center flex flex-col gap-1">
                                          <div className="text-xl">{purchase.beatTitle}</div>
                                          {/* <div className="text-sm font-medium text-[#9F9F9F]">{beat.artistName}</div> */}
                                          <div className="text-sm text-[#9F9F9F]">{purchase.bpm} BPM</div>
                                      </div>
                                      <div className="border-t border-t-[#9F9F9F] mx-7 my-2"></div>
                                      <div className="flex flex-col justify-center items-center gap-2 w-full">
                                          <button
                                              onClick={() => DownloadStems(purchase.zipFileLink)}
                                              className="bg-[#FF3E41] hover:scale-110 hover:transition-transform duration-200 px-4 py-2 w-[70%] rounded-md"
                                          >
                                              Download Stems
                                          </button>
                                      </div>
                                  </div>
                              </div>

                         ) })

                        }
                        </div>
                  

                     <PricingModel isOpen={isOpen} loading={loadingl} setLoading={setLoadingl}  isAllow={isAllow} isAllowStems={isAllowStems} setIsAllow={setIsAllow} setIsAllowStems={setIsAllowStems} onClose={HandleClosePricing} user={user} beat={selectedBeat} type='exclusive'/>
                    <MusicPlayer type={'exclusive'} section='purchased' selectedBeat={selectedBeat} setSelectedBeat={setSelectedBeat} isOpen={isOpen} setIsOpen={setIsOpen} tracks={purchasedData.beats} isPlaying={isPlaying} setIsPlaying={setIsPlaying}  userId={user?._id} user={user} scrollToTop={scrollToTop} musicIndex={musicIndex} setMusicIndex={setMusicIndex}/>


                    </div>

                    )
                }
        
            </Section>
        </UserLayout>
    );
};

export default PurchasedHistory;
