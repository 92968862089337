import React, { useState, useRef, useEffect } from "react";
import audio from "../assets/audio.svg";
import musicIcon from "../assets/musicIcon.svg";
import hearticon from "../assets/hearticon.svg";
import fastforward from "../assets/fastforward.svg";
import circlePlay from "../assets/circlePlay.svg";
import rewind from "../assets/rewind.svg";
import shuffle from "../assets/shuffle.svg";
import download from "../assets/download.svg";
import { TbPlayerPauseFilled, TbPlayerPlayFilled } from "react-icons/tb";
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from "react-icons/bs";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  fetchFavorites,
  removeFavorite,
} from "../redux/favouriteSlice";
import toast from "react-hot-toast";
import PricingModel from "./PricingModel";
import { useNavigate } from "react-router-dom";
import { BsShuffle } from "react-icons/bs";
import { FaShuffle } from "react-icons/fa6";
import IconLucideShuffle from "../UI/IconLucidShuffle";
import { backend_url } from "../libs/data";
import { differenceInDays, formatDistanceToNowStrict } from "date-fns";
import axios from "axios";

const MusicPlayer = ({
  type,
  tracks,
  section,
  favorites = [],
  userId,
  user,
  scrollToTop,
  setMusicIndex,
  musicIndex,
  isPlaying,
  setIsPlaying,
  selectedBeat,
  setSelectedBeat,
  isOpen,
  setIsOpen,
  isAllow,
  setIsAllow,
  isAllowStems,
  setIsAllowStems,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(musicIndex);
  const [hoverTime, setHoverTime] = useState(null);
  const progressRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [isVolumeVisible, setIsVolumeVisible] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [playStartTime, setPlayStartTime] = useState(null);
  const [playedTracks, setPlayedTracks] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    const audioElement = audioRef.current;

    if (!audioElement) return;

    const handleDurationChange = () => {
      setDuration(audioElement.duration || 0);
    };
    const handleTimeUpdate = () => {
      setCurrentTime(audioElement.currentTime || 0);
    };

    audioElement.addEventListener("loadedmetadata", handleDurationChange);
    audioElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (audioElement) {
        audioElement.removeEventListener(
          "loadedmetadata",
          handleDurationChange
        );
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [musicIndex]);

  useEffect(() => {
    if (tracks.length > 0) {
      const audioElement = audioRef.current;
      setDuration(audioElement.duration || 0);
    }
  }, [tracks]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleProgressClick = (e) => {
    if (!progressRef.current || !audioRef.current) return;

    const rect = progressRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = Math.min(
      Math.max((offsetX / rect.width) * duration, 0),
      duration
    );
    setCurrentTime(newTime);
    audioRef.current.currentTime = newTime;
  };

  const handleMouseMove = (e) => {
    if (isDragging && progressRef.current) {
      const rect = progressRef.current.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const newTime = Math.min(
        Math.max((offsetX / rect.width) * duration, 0),
        duration
      );
      setHoverTime(newTime);
    }
  };

  const HandleClosePricing = () => {
    setIsOpen(false);
    setSelectedBeat(null);
  };
  const HandleDownload = () => {
    setIsOpen(true);
    setSelectedBeat(tracks[musicIndex]);
  };

  const GetAllowInfo = async (userId, beatsId) => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
    try {
      setLoading(true);
      const res = await axios.get(
        `${backend_url}/api/checkCreditDownload/${userId}/${beatsId}`
      );

      if (res.data.isAllowDownload) {
        setIsAllow(true);
      } else {
        setIsAllow(false);
      }

      if (res.data.isAllowStems) {
        setIsAllowStems(true);
      } else {
        setIsAllowStems(false);
      }

      HandleDownload();
    } catch (error) {
      if (error.response?.status === 404) {
        console.warn("Beat not found in user downloads.");
        //  toast.error("Beat not found. Please try a different beat.");
      } else {
        console.error("An error occurred:", error.message);
        // toast.error("An error occurred while checking download permissions.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMouseDown = () => setIsDragging(true);
  const handleMouseUp = () => setIsDragging(false);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  const handleNextTrack = () => {
    if (isShuffle) {
      const remainingTracks = tracks.filter(
        (_, index) => !playedTracks.includes(index)
      );

      if (remainingTracks.length === 0) {
        setPlayedTracks([]);
        const randomIndex = Math.floor(Math.random() * tracks.length);
        setMusicIndex(randomIndex);
        setSelectedBeat(tracks[randomIndex]);
      } else {
        const randomIndex = Math.floor(Math.random() * remainingTracks.length);
        const selectedIndex = tracks.indexOf(remainingTracks[randomIndex]);

        setMusicIndex(selectedIndex);
        setSelectedBeat(remainingTracks[randomIndex]);
        setPlayedTracks((prev) => [...prev, selectedIndex]);
      }
    } else {
      setMusicIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % tracks.length;
        setSelectedBeat(tracks[newIndex]);
        return newIndex;
      });
    }

    audioRef.current.currentTime = 0;
    setIsPlaying(true);
  };

  const handlePrevTrack = () => {
    if (isShuffle) {
      const remainingTracks = tracks.filter(
        (_, index) => !playedTracks.includes(index)
      );

      if (remainingTracks.length === 0) {
        setPlayedTracks([]);
        const randomIndex = Math.floor(Math.random() * tracks.length);
        setMusicIndex(randomIndex);
        setSelectedBeat(tracks[randomIndex]);
      } else {
        const randomIndex = Math.floor(Math.random() * remainingTracks.length);
        const selectedIndex = tracks.indexOf(remainingTracks[randomIndex]);

        setMusicIndex(selectedIndex);
        setSelectedBeat(remainingTracks[randomIndex]);
        setPlayedTracks((prev) => [...prev, selectedIndex]);
      }
    } else {
      setMusicIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + tracks.length) % tracks.length;
        setSelectedBeat(tracks[newIndex]);
        return newIndex;
      });
    }

    audioRef.current.currentTime = 0;
    setIsPlaying(true);
  };

  const handleMetadataLoad = (event) => {
    const duration = event.target.duration;
    if (duration) {
      setDuration(duration);
    }
  };

  useEffect(() => {
    setPlayedTracks([]);
  }, [tracks]);

  useEffect(() => {
    if (musicIndex) {
      audioRef.current.currentTime = 0;
    }
  }, [musicIndex]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  const toggleVolumeVisibility = () => {
    setIsVolumeVisible(!isVolumeVisible);
  };

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  const isFavorite = favorites.some(
    (fav) => fav._id === tracks[musicIndex]?._id
  );

  const handleFavoriteToggle = () => {
    const beatId = tracks[musicIndex]._id;
    if (isFavorite) {
      dispatch(removeFavorite({ userId, beatId }))
        .then(() => {
          dispatch(fetchFavorites(userId));
          toast.success(
            `${tracks[musicIndex].beatTitle} removed from favorites`
          );
        })
        .catch((error) => {
          toast.error("Failed to remove from favorites");
        });
    } else {
      dispatch(addFavorite({ userId, beatId }))
        .then(() => {
          dispatch(fetchFavorites(userId));
          toast.success(`${tracks[musicIndex].beatTitle} added to favorites`);
        })
        .catch((error) => {
          toast.error("Failed to add to favorites");
        });
    }
  };

  const handleShuffleToggle = () => {
    setIsShuffle((prev) => !prev);
  };
  useEffect(() => {
    if (isPlaying) {
      setPlayStartTime(Date.now());
    }
  }, [isPlaying]);
  const callApi = (playbackTime) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      userId,
      beatId: tracks[musicIndex]?._id,
      playbackTime: playbackTime,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${backend_url}/api/play`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const getTimeAgo = (date) => {
    const release = new Date(date);
    if (isNaN(release.getTime())) {
      return "Invalid date"; // Handle invalid dates
    }
    return formatDistanceToNowStrict(release, { addSuffix: true });
  };
  useEffect(() => {
    if (!isPlaying && playStartTime) {
      const playbackDuration = (Date.now() - playStartTime) / 1000;
      if (playbackDuration >= 10) {
        callApi(playbackDuration);
      }
      setPlayStartTime(null);
    }
  }, [callApi, isPlaying, playStartTime]);
  if (tracks.length === 0) return null;

  return (
    <div className="pt-40">
      <div className="bg-[#141414]  bg-opacity-30 backdrop-blur-lg  p-2 flex md:flex-row flex-col items-center fixed bottom-0 left-0 z-10 md:z-[9999999999] px-5 lg:px-20 justify-between shadow-lg w-full ">
        <div className="flex items-center  md:justify-start justify-center gap-4">
          <img
            src={musicIcon}
            alt=""
            className="h-6 w-6 hidden md:h-16 md:w-16 lg:block"
          />
          <div className="md:hidden">
            {tracks[musicIndex].beatType === "Non-Exclusive" &&
              (section === "purchased" ? (
                ""
              ) : isFavorite ? (
                <IoHeart
                  color="#FF3E41"
                  size={30}
                  onClick={handleFavoriteToggle}
                  className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
                />
              ) : (
                <IoHeartOutline
                  color="#FF3E41"
                  size={30}
                  className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
                  onClick={handleFavoriteToggle}
                />
              ))}
          </div>
          <div>
            <h2 className="ml-[3px] lg:ml-[0px] text-white text-xl ">
              {tracks[musicIndex]?.beatTitle}
            </h2>
            <p className="md:block hidden">{tracks[musicIndex]?.artistName}</p>
            <p className="md:block hidden">{tracks[musicIndex]?.bpm} BPM</p>
            <p className="md:block hidden">
              {getTimeAgo(tracks[musicIndex]?.releaseDate)}
            </p>
            <div className="space-x-2 text-sm md:grid grid-cols-3 gap-1 text-start hidden w-full ">
              {tracks[musicIndex]?.tags?.map((tag, index) => (
                <span
                  key={index}
                  className="bg-black text-white px-1 rounded-md truncate"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
          <div className="md:hidden">
            {tracks[musicIndex].beatType === "Non-Exclusive" &&
              section !== "purchased" && (
                <div className="gap-5 flex items-center justify-end md:justify-between">
                  <div className="flex items-center">
                    <button
                      className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-2 rounded-md"
                      onClick={() =>
                        GetAllowInfo(userId, tracks[musicIndex]?._id)
                      }
                    >
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.8em"
                          height="1.8em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                          >
                            <animateTransform
                              attributeName="transform"
                              dur="0.75s"
                              repeatCount="indefinite"
                              type="rotate"
                              values="0 12 12;360 12 12"
                            />
                          </path>
                        </svg>
                      ) : (
                        <img src={download} alt="" />
                      )}
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className=" md:w-1/2 w-full flex py-4 flex-col justify-center items-center ">
          <div className=" ml-[-50px] transform -translate-y-2 flex justify-center md:h-12 md:w-12 h-5 items-center lg:py-0 py-6 gap-5 min-w-full ;">
            <div className="flex items-center justify-center ">
              <IconLucideShuffle
                width="24px"
                height="24px"
                style={{
                  color: isShuffle ? "#FF3E41" : "#BF3E3A",
                  transition: "all 0.3s ease",
                }}
                onClick={handleShuffleToggle}
              />
              <div
                className={` ${
                  isShuffle
                    ? "bg-[#FF3E41] w-[5px] h-[5px] mr-1 rounded-full  opacity-100 transition-opacity ease-linear duration-300"
                    : " opacity-0 bg-[#FF3E41] w-[5px] h-[5px] mr-1 rounded-full transition-opacity ease-linear duration-300"
                }`}
              ></div>
            </div>
            <img
              src={rewind}
              alt=""
              onClick={handlePrevTrack}
              className=" cursor-pointer"
            />
            <div
              className="rounded-full bg-[#ff3e41] p-2 cursor-pointer"
              onClick={togglePlayPause}
            >
              {isPlaying ? (
                <TbPlayerPauseFilled size={25} />
              ) : (
                <TbPlayerPlayFilled size={25} />
              )}
            </div>
            <img
              src={fastforward}
              alt=""
              onClick={handleNextTrack}
              className=" cursor-pointer"
            />
          </div>
          <div className="flex items-center w-full">
            <div className="text-[#FFFFFF]">{formatTime(currentTime)}</div>
            <div
              className="relative flex-grow mx-2"
              ref={progressRef}
              onMouseMove={handleMouseMove}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={() => setHoverTime(null)}
              onClick={handleProgressClick}
            >
              <div className="h-2 bg-[#FFFFFF] rounded-full w-full">
                <div
                  className="h-2 bg-[#FF3E41] rounded-full"
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                />
              </div>
              {hoverTime !== null && (
                <div
                  className="absolute top-0 transform -translate-x-1/2 -translate-y-full bg-black text-white text-xs rounded px-2 py-1"
                  style={{ left: `${(hoverTime / duration) * 100}%` }}
                >
                  {formatTime(hoverTime)}
                </div>
              )}
            </div>
            <div className="text-[#FFFFFF]">{formatTime(duration)}</div>
          </div>
        </div>
        <div className="md:relative  md:right-0 absolute md:top-0 right-2 top-[3.15rem] ">
          <div className="flex items-center gap-2 hidden">
            <div className="cursor-pointer" onClick={toggleVolumeVisibility}>
              {volume > 0 ? (
                <BsFillVolumeUpFill size={25} className="text-[#FF3E41]" />
              ) : (
                <BsFillVolumeMuteFill size={25} className="text-[#FF3E41]" />
              )}
            </div>

            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={(e) => setVolume(parseFloat(e.target.value))}
              className="lg:w-28 w-14 h-1 bg-gray-400 rounded-lg appearance-none focus:outline-none"
            />
            <style jsx>{`
              input[type="range"] {
                -webkit-appearance: none;
                background: linear-gradient(
                  to right,
                  #ff3e41 ${volume * 100}%,
                  #ffffff 0%
                );
                background-color: #gray;
                height: 5px;
                border-radius: 5px;
                cursor: pointer;
              }

              input[type="range"]::-webkit-slider-thumb {
                appearance: none;
                width: 12px;
                height: 12px;
                background: #ff3e41;
                border-radius: 50%;
                cursor: pointer;
                transition: background 0.3s ease, width 0.3s ease,
                  height 0.3s ease;
                z-index: 1;
              }
              input[type="range"]:hover::-webkit-slider-thumb {
                background: #ff3e41;
                width: 14px;
                height: 14px;
              }
              input[type="range"]::-moz-range-thumb {
                appearance: none;
                width: 12px;
                height: 12px;
                background: white;
                border-radius: 50%;
                cursor: pointer;
                transition: background 0.3s ease, width 0.3s ease,
                  height 0.3s ease;
              }
              input[type="range"]:hover::-moz-range-thumb {
                background: #ff3e41;
              }
              input[type="range"]::-ms-thumb {
                width: 12px;
                height: 12px;
                background: #ff3e41;
                border-radius: 50%;
                cursor: pointer;
                transition: background 0.3s ease;
              }
              input[type="range"]:hover::-ms-thumb {
                background: #ff3e41;
              }
            `}</style>
          </div>
        </div>
        {tracks[musicIndex].beatType === "Non-Exclusive" &&
          section !== "purchased" && (
            <div className="gap-5 md:flex hidden items-center justify-end md:justify-between">
              {section === "purchased" ? (
                ""
              ) : isFavorite ? (
                <IoHeart
                  color="#FF3E41"
                  size={30}
                  onClick={handleFavoriteToggle}
                  className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
                />
              ) : (
                <IoHeartOutline
                  color="#FF3E41"
                  size={30}
                  className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
                  onClick={handleFavoriteToggle}
                />
              )}
              <div className="flex items-center">
                <button
                  className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md"
                  onClick={() => GetAllowInfo(userId, tracks[musicIndex]?._id)}
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.8em"
                      height="1.8em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        />
                      </path>
                    </svg>
                  ) : (
                    <img src={download} alt="" />
                  )}
                </button>
              </div>
            </div>
          )}
        {section === "purchased" && (
          <div className="gap-5 md:flex hidden items-center justify-end md:justify-between">
            {section === "purchased" ? (
              ""
            ) : isFavorite ? (
              <IoHeart
                color="#FF3E41"
                size={30}
                onClick={handleFavoriteToggle}
                className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
              />
            ) : (
              <IoHeartOutline
                color="#FF3E41"
                size={30}
                className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer"
                onClick={handleFavoriteToggle}
              />
            )}
            <div className="flex items-center">
              <button
                className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md"
                onClick={() => GetAllowInfo(userId, tracks[musicIndex]?._id)}
              >
                {loading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.8em"
                    height="1.8em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
                    >
                      <animateTransform
                        attributeName="transform"
                        dur="0.75s"
                        repeatCount="indefinite"
                        type="rotate"
                        values="0 12 12;360 12 12"
                      />
                    </path>
                  </svg>
                ) : (
                  <img src={download} alt="" />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      <audio
        src={tracks[musicIndex]?.streamingMp3FileLink || ""}
        ref={audioRef}
        autoPlay={isPlaying}
        onEnded={handleNextTrack}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
      />

      <PricingModel
        isOpen={isOpen}
        isAllow={isAllow}
        loading={loading}
        setLoading={setLoading}
        isAllowStems={isAllowStems}
        setIsAllow={setIsAllow}
        setIsAllowStems={setIsAllowStems}
        onClose={HandleClosePricing}
        user={user}
        beat={selectedBeat}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

export default MusicPlayer;
