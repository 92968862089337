import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import avatar from '../assets/avatar.svg';
import searchIcon from '../assets/search.svg';
import logoutIcon from '../assets/logout.svg';
import homeIcon from '../assets/home.svg';
import userIcon from '../assets/user.svg';
import shoppingIcon from '../assets/shopping.svg';
import heartIcon from '../assets/heart.svg';
import settingIcon from '../assets/setting.svg';
import musicIcon from '../assets/music.svg';
import analyticsIcon from '../assets/analytics.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/authSlice';
import { fetchUser } from '../redux/userSlice';

const userNavLinks = [
  { src: shoppingIcon, label: 'Dashboard', path: '/user-dashboard' },
  { src: homeIcon, label: 'Purchase History', path: '/purchase-history' },
  { src: heartIcon, label: 'Favorite', path: '/favourite' },
  { src: settingIcon, label: 'Account Setting', path: '/account-setting' },
];

const adminNavLinks = [
  { src: homeIcon, label: 'Dashboard', path: '/admin/dashboard' },
  { src: musicIcon, label: 'Beats Management', path: '/admin/beats-management' },
  { src: userIcon, label: 'User Management', path: '/admin/user-management' },
  { src: analyticsIcon, label: 'Analytics', path: '/admin/analytics' },
];

const UserDashboardSideBar = ({ sidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const currentPath = location.pathname;
  const isAdminPanel = currentPath.startsWith('/admin');
  const { user,loading } = useSelector((state) => state.user)
  const [profileColor,setProfileColor] = useState('')

  useEffect(() => {
    const storedColor = localStorage.getItem('profileColor');
    if (storedColor) {
        setProfileColor(storedColor);
    } else {
        const getRandomDarkColor = () => {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 4)];
            }
            return color;
        };

        const newColor = getRandomDarkColor();
        setProfileColor(newColor);
        localStorage.setItem('profileColor', newColor);
    }
}, []);

   useEffect(()=>{
     dispatch(fetchUser())
   },[dispatch])
  const navLinks = isAdminPanel ? adminNavLinks : userNavLinks;

  const ShowUserType = (type)=>{
    if(type==="freeUser")
    {
      return "Free User"
    }else if(type==="vipUser")
    {
      return "VIP User"
    }
    else if(type==="invitedUser")
    {
      return "Invited User"
    }
  }
  return (
    <div className={`bg-[#141414] text-white fixed left-0 bottom-0 md:relative overflow-y-auto top-0 w-full md:w-64 z-30 transition-transform duration-300 ${sidebarOpen ? 'translate-y-0' : '-translate-y-full md:translate-y-0'}`}>
      <div className="flex flex-col items-center py-8">
        <Link to={'/'} className='w-[110px] h-[110px]'>
        <div className=' w-[100%] h-[100%] rounded-full object-cover text-white flex items-center justify-center ' >
            {user && user.profileImage ? (
            
                <img src={user.profileImage} className='py-2 w-full h-full rounded-full object-cover' alt="User Profile" />
              
            ) : (
                <div style={{ backgroundColor: profileColor }} className='w-full h-full rounded-full flex items-center justify-center text-2xl'>
                    {user && user.fullName ? user.fullName.slice(0,2) : ""} 
                </div>
            )}
        </div>
        </Link>
        <h3 className="text-xl font-bold">{user && user.fullName}</h3>
        <p className='text-[#FF3E41] text-sm'>{ShowUserType(user && user.userType)}</p>
        <span className="text-sm text-gray-400">Credits: {user && user?.Credits || '0'}</span>
        <button className="md:hidden block absolute top-4 right-4" onClick={toggleSidebar}>
          {sidebarOpen ? (
            <FaTimes className="text-white text-2xl" />
          ) : (
            <FaBars className="text-white text-2xl" />
          )}
        </button>
      </div>
      <nav className="flex flex-col px-5 gap-3">
        <div className='flex flex-col gap-5'>
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className={`flex gap-2 items-center py-3 px-3 rounded-md cursor-pointer transition-transform duration-300 ${currentPath === link.path ? 'bg-red-500 text-white' : 'text-white hover:bg-red-500'
                }`}
            >
              <img src={link.src} alt={link.label} />
              {link.label}
            </Link>
          ))}
        </div>
        <div className="flex items-center py-20 justify-center w-full">
          <button onClick={()=>{
              dispatch(logout())
          }} className="flex items-center justify-center gap-2 py-3 px-4 w-full bg-white rounded-sm text-red-500 hover:scale-110 transition-transform duration-200">
            <img src={logoutIcon} alt="Logout" />
            <span>Logout</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default UserDashboardSideBar;
