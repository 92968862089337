import React, { useState } from 'react';
import UserLayout from '../../layout/UserLayout';
import ChartsCards from '../../components/ChartsCards';
import Section from '../../components/Section';

const Analystics = () => {
    const [selectedCategory, setSelectedCategory] = useState('global');

    return (
        <>
            <UserLayout>
                <Section>
                    <div className="bg-black px-5 md:px-10">
                        <div className="md:px-5 py-8">
                            <h1 className="text-2xl font-bold text-white">Analytics</h1>
                        </div>
                        <div className="lg:px-12 py-2 flex gap-7 lg:gap-[7rem]">
                            <button
                                className={`rounded-full px-4 py-2 ${selectedCategory === 'global' ? 'bg-red-500 text-white' : 'bg-transparent text-white'}`}
                                onClick={() => setSelectedCategory('global')}
                            >
                                Global
                            </button>
                            <button
                                className={`rounded-full px-4 py-2 ${selectedCategory === 'beats' ? 'bg-red-500 text-white' : 'bg-transparent text-white'}`}
                                onClick={() => setSelectedCategory('beats')}
                            >
                                Beats
                            </button>
                            <button
                                className={`rounded-full px-4 py-2 ${selectedCategory === 'users' ? 'bg-red-500 text-white' : 'bg-transparent text-white'}`}
                                onClick={() => setSelectedCategory('users')}
                            >
                                Users
                            </button>
                        </div>
                        <ChartsCards selectedCategory={selectedCategory} />
                    </div>
                </Section>
            </UserLayout>
        </>
    );
};

export default Analystics;
