import React, { useEffect, useState } from 'react'
import UserLayout from '../../layout/UserLayout'
import avatar from '../../assets/avatar.svg'
import Section from '../../components/Section';
import { backend_url } from '../../libs/data';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { fetchUser } from '../../redux/userSlice';
import PasswordChangeWarning from '../../UI/PasswordChangeWarning';

const AccountSetting = () => {
  const [profileImage, setProfileImage] = useState(avatar);
  const [userData,setUserData] = useState('');
  const [Loading, setLoading] = useState(false);
  const [passLoading, setpassLoading] = useState(false);
  const email = localStorage.getItem("email");
  const [updateError,setUpdateError] = useState({})
  const [showWarning, setShowWarning] = useState(false);
  const [passwordError,setPasswordError] = useState({})
 const [passwordChanged,setPasswordChanged] = useState(false)
 const [isPasswordVisible, setIsPasswordVisible] = useState({
  currentPassword: false,
  newPassword: false,
  confirmPassword: false,
});

const togglePasswordVisibility = (field) => {
  setIsPasswordVisible((prevState) => ({
    ...prevState,
    [field]: !prevState[field],
  }));
};

  const handleCloseWarning = () => {
    setShowWarning(false);
    setPasswordChanged(false);
  };

  const dispatch = useDispatch();
   const { user,loading } = useSelector((state) => state.user)

   useEffect(()=>{
     dispatch(fetchUser())
   },[dispatch])

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  
  const UpdateValidation =()=>{
    let error={};
    if(!formData.username)
    {
      error.username = "Username is required";
    }
    if(!profileImage)
      {
        error.profileImage = "Profile Picture is required";
      }
    if(!formData.name)
      {
        error.profileImage = "name is required";
      }
    setUpdateError(error)
    return Object.keys(error).length === 0;
  }
  const PasswordValidation = () => {
    let error = {};
    if (!formData.currentPassword) {
      error.currentPassword = 'Current Password is required';
    }
    if (!formData.newPassword) {
      error.newPassword = 'New Password is required';
    }
    if (formData.newPassword?.length < 8) {
      error.newPassword = 'Password must be at least 8 characters long';
    }
    if (formData.newPassword !== formData.confirmPassword) {
      error.confirmPassword = 'New password and confirm password must match';
    }
    setPasswordError(error);
    return Object.keys(error).length === 0;
  };
  const HandleUpdateData = async()=>{
     
    if(!UpdateValidation())
    {
      return
    }
    try{
      setLoading(true);
      const data = {
        username:formData.username,
        profileImage:profileImage,
        name:formData.name
      }
      await axios.post(`${backend_url}/api/updateinfo`, {
        email,data
    });
    toast.success("User Info Updated")

    }
    catch(error)
    {
      const errorMessage = error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
       toast.error(errorMessage)
    }
    finally{
      setLoading(false)
    }

  }
  const HandleChangePassword = async()=>{
     
    if(!PasswordValidation())
    {
      return
    }
    try{
      setpassLoading(true);
      await axios.post(`${backend_url}/api/changePassword`, {
        password:formData.currentPassword,
        newPassword:formData.newPassword,
        email:formData.email
    });
    toast.success("Password Changed")
    setFormData({
      ...formData,
      currentPassword:'',
      newPassword:'',
      confirmPassword:''
    })
    setShowWarning(false)
    setPasswordChanged(true)

    }
    catch(error)
    {
      const errorMessage = error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
       toast.error(errorMessage)
    }
    finally{
      setpassLoading(false)
    }

  }

  useEffect(()=>{
    if(user)
    {
      setFormData({
        name:user.fullName,
        email:user.email,
        username:user?.username || '',
      })
      setProfileImage(user.profileImage)
    }

  },[user])


  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        
      if (!allowedTypes.includes(file.type)) {
          toast.error('Please upload a valid image file (PNG, JPEG, JPG).');
          return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRemoveError = (field)=>{
    setUpdateError((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };
  useEffect(() => {
    if (user) {
      if (user?.hasPasswordChanged === false && !passwordChanged) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  }, [user, passwordChanged]);

  return (
    <>
      <UserLayout>
        <Section>
          <div className="md:px-10">
          <PasswordChangeWarning show={showWarning} onClose={handleCloseWarning} />
            <div className="container mx-auto p-4 text-white">
              <h2 className="text-3xl font-semibold py-5">Account Settings</h2>
              <h2 className='text-[#9F9F9F] py-4'>Basic Information</h2>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="space-y-4 lg:order-1 order-2">
                  <div>
                    <label className="block text-sm text-[#9F9F9F]  font-medium py-1" htmlFor="name">
                      Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full px-3 py-2 bg-white rounded-md text-black"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-[#9F9F9F]  font-medium py-1" htmlFor="email">
                      Email*
                    </label>
                    <input
                    disabled
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full px-3 py-2 bg-white rounded-md text-black"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-[#9F9F9F]  font-medium py-1" htmlFor="username">
                      Username
                    </label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      value={formData.username}
                      onFocus={()=>handleRemoveError('username')}
                      onChange={handleChange}
                      className="w-full px-3 py-2 bg-white rounded-md text-black"
                      placeholder="Enter Username"
                    />
                    {updateError.username && <div className="text-red-500 text-sm">{updateError.username}</div>}

                  </div>
                </div>
                <div className="lg:order-2 order-1">
                  <div className="flex flex-col gap-2 items-center">
                    <label className="block text-sm font-medium mb-1" htmlFor="profilePicture">
                      Profile Picture
                    </label>
                    <div className="relative w-32 h-32">
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="rounded-full w-full h-full object-cover"
                      />
                      <input
                        type="file"
                        id="profilePicture"
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={(e)=>{
                          handleImageUpload(e)
                          handleRemoveError('profileImage')
                        }}
                      />
                    </div>
                    <label htmlFor="profilePicture" className="mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-xl cursor-pointer">
                    Upload
                  </label>
                    {updateError.profileImage && <div className="text-red-500 text-sm">{updateError.profileImage}</div>}

                  </div>
                </div>
              </div>
              <button onClick={()=>{
                HandleUpdateData()
              }} className="mt-5 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-xl">
                      {Loading ? "Updating..." : "Update"}
              </button>
              <div className="mt-8 space-y-4">
                <h3 className="text-2xl font-semibold">Change Password</h3>
                <div>
                <label className="block text-sm text-[#9F9F9F] font-medium py-1" htmlFor="currentPassword">
                  Current Password*
                </label>
                <div className="relative w-full">
                  <input
                    type={isPasswordVisible.currentPassword ? "text" : "password"}
                    id="currentPassword"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleChange}
                    className="w-full px-3 py-2 bg-white rounded-md text-black"
                    placeholder="Enter your current password"
                  />
                  
                  <div onClick={() => togglePasswordVisibility('currentPassword')}  className="absolute right-3 top-[10px]">
                  {isPasswordVisible.currentPassword ? <LuEye color='black'  size={20}/> : <LuEyeOff color='black'  size={20}/>}
                  </div>
                 
                  {passwordError.currentPassword && (
                    <div className="text-red-500 text-sm">{passwordError.currentPassword}</div>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-[#9F9F9F] py-1" htmlFor="newPassword">
                  New Password*
                </label>
                <div className="relative">
                  <input
                    type={isPasswordVisible.newPassword ? "text" : "password"}
                    id="newPassword"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                    className="w-full px-3 py-2 bg-white rounded-md text-black"
                    placeholder="Create a new password"
                  />
                   <div onClick={() => togglePasswordVisibility('newPassword')}  className="absolute right-3 top-[10px]">
                  {isPasswordVisible.newPassword ? <LuEye color='black'  size={20}/> : <LuEyeOff color='black'  size={20}/>}
                  </div>
                
                  {passwordError.newPassword && (
                    <div className="text-red-500 text-sm">{passwordError.newPassword}</div>
                  )}
                  <p className="text-red-500 text-sm mt-1">Must be at least 8 characters.</p>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-[#9F9F9F] py-1" htmlFor="confirmPassword">
                  Confirm Password*
                </label>
                <div className="relative">
                  <input
                    type={isPasswordVisible.confirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="w-full px-3 py-2 bg-white rounded-md text-black"
                    placeholder="Enter password to confirm"
                  />
                   <div onClick={() => togglePasswordVisibility('confirmPassword')}  className="absolute right-3 top-[10px]">
                  {isPasswordVisible.confirmPassword ? <LuEye color='black'  size={20}/> : <LuEyeOff color='black'  size={20}/>}
                  </div>
                 
                  {passwordError.confirmPassword && (
                    <div className="text-red-500 text-sm">{passwordError.confirmPassword}</div>
                  )}
                </div>
              </div>

                <button onClick={()=>{HandleChangePassword()}} className="mt-5 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-xl">
                      {passLoading ? "Changing Password..." : "Change Password"}
              </button>
              </div>
            </div>
          </div>
        </Section>
      </UserLayout>
    </>
  )
}

export default AccountSetting