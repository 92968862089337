import React, { useEffect, useState } from 'react';
import boxcircle from '../assets/boxcircle.svg';
import { IoMdClose } from 'react-icons/io';
import axios from 'axios';
import { backend_url } from '../libs/data';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../redux/userSlice';
import spinner from '../assets/spinner.svg'
import { addToStemsCart, removeFromStemsCart } from '../redux/stemsCartSlice';
import { useLocation } from 'react-router-dom';


const PricingModel = ({ isOpen, onClose, user, beat,type,isAllow,setIsAllow, isAllowStems,setIsAllowStems,loading,setLoading}) => {
    const dispatch = useDispatch()
    // const [isAllow,setIsAllow] = useState(false)
    // const [isAllowStems,setIsAllowStems] = useState(false)
   
    const [downloadLoading,setDownloadLoading] = useState({})
    const stemsCartItems = useSelector((state) => state.stemscart.cartItems);
    const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);

    const loc = useLocation()
    

    

    const GetAllowInfo = async (userId, beatsId) => {
        try {
            setLoading(true);
            const res = await axios.get(`${backend_url}/api/checkCreditDownload/${userId}/${beatsId}`);
            
            if (res.data?.isAllowDownload) {
                setIsAllow(true);
            } else {
                setIsAllow(false);
            }
    
            if (res.data?.isAllowStems) {
                setIsAllowStems(true);
            } else {
                setIsAllowStems(false);
            }
    
        } catch (error) {
            if (error.response?.status === 404) {
                console.warn("Beat not found in user downloads.");
              //  toast.error("Beat not found. Please try a different beat.");
            } else {
                console.error("An error occurred:", error.message);
                // toast.error("An error occurred while checking download permissions.");
            }
        } finally {
            setLoading(false);
        }
    };
    
      


    //   useEffect(() => {
    //     if (isOpen && user && beat) {
    //         GetAllowInfo(user._id, beat._id);
    //     }
    // }, [isOpen, user, beat]);
    

    if (!isOpen) return null;

    const isInCart = stemsCartItems?.some((item) => item._id === beat._id);


    const handleAddToCart = () => {
        dispatch(addToStemsCart({ userId:user._id, beatId: beat._id, isLoggedIn }))
        .then(() => {
            toast.success(`${beat.beatTitle} Added to Cart`);
        })
        .catch((error) => {
            toast.error('Failed to add to Cart');
        });
    };

    const handleRemoveFromCart = () => {
        dispatch(removeFromStemsCart({ userId:user._id, beatId: beat._id, isLoggedIn }))
        .then(() => {
            toast.success(`${beat.beatTitle} Removed from Cart`);
        })
        .catch((error) => {
            toast.error('Failed to removed from Cart');
        });
    };

    
    let plans = [
        {
            name: 'VIP',
            planType:'vipPlan',
            price: '99',
            heading: 'What You’ll Get',
            features: [
                '300+ free beatsssss upfront',
                'Up to 5 new beats every week',
                'Keep 100% royalties/profits to every track',
                'Non Exclusive Licences'
            ],
            buttonText: 'Upgrade to VIP',
            buttonColor: 'text-white'
        },
        {
            name: 'Stems',
            planType:'stems',
            price: '25',
            heading: 'What You’ll Get',
            features: [
                'Offers to Purchase tracked-out Stems to a beat',
            ],
            buttonText: 'Purchase Stems',
            buttonColor: 'text-white'
        },
        {
            name: 'Exclusive',
            planType:'exclusivePlan',
            price: 'XX',
            heading: 'What You’ll Get',
            features: [
                'Exclusive Rights to a beat',
                'Stems Included',
                'Keep 100% royalties and profits',
            ],
            buttonText: 'Purchase Exclusive',
            buttonColor: 'text-white'
        }
    ];

    if (user.userType === "vipUser") {
        plans = plans.filter(plan => plan.planType !== 'vipPlan');
    }
   
    const HandlePurchasePlan = async(planType)=>{
        if(planType==="stems")
        {
            try{
                const res = await axios.post(`${backend_url}/create-checkout-session-stems`,{
                    beatDetails:[
                        {
                        price: 25,
                        beatId:beat._id,
                        beatName:beat.beatTitle
                    }
                  ],
                    email:user.email,
                    sessionType:planType, 
                    userId:user._id,
                     
                })
    
                window.location.replace(res.data.session.url);
            }
            catch(error)
            {
                const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                toast.error(errorMessage);
    
            }
        }
        else if(planType==="exclusivePlan")
        {
            if(!beat.price)
            {
                toast.error("No price for this beat")
            }
            try{
                const res = await axios.post(`${backend_url}/create-checkout-session-exclusive`,{
            
                    price: beat.price,
                    beatId:beat._id,
                    beatName:beat.beatTitle,
                    email:user.email,
                    sessionType:planType, 
                    userId:user._id,
                     
                })
    
                window.location.replace(res.data.session.url);
            }
            catch(error)
            {
                const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                toast.error(errorMessage);
    
            }
        }
        else{
            try{
                const res = await axios.post(`${backend_url}/create-checkout-session-plan`,{
                    price: '99',
                    email:user.email,
                    sessionType:planType, 
                    userId:user._id 
                })
    
                window.location.replace(res.data.session.url);
            }
            catch(error)
            {
                const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                toast.error(errorMessage);
    
            }

        }
       
      
    }

    const handleDownload = async (fileLink,fileType,buttonKey) => {
        if(isAllow)
        {
            await handleDownloadBeat(fileLink,fileType,buttonKey)
        }
        else{
            try{
                const res =  await axios.post(`${backend_url}/api/reduce-credits`,{
                   email:user.email,
                   beatId:beat._id
                })
                if(res.status===200)
                {
                await handleDownloadBeat(fileLink,fileType)
                 dispatch(fetchUser())
                 GetAllowInfo(user._id,beat._id)
                }
             }
             catch(error)
             {
                 const errorMessage = error.response && error.response.data.message
                 ? error.response.data.message
                 : error.message;
                 toast.error(errorMessage);
             }

        }
       

        
    };
    const handleDownloadBeat = async (fileLink, fileType,buttonKey) => {
        try {
            setDownloadLoading((prevState) => ({ ...prevState, [buttonKey]: true }));
          const token = localStorage.getItem("token");
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${token}`);
  
          const requestBody = JSON.stringify({
            beatId: beat._id,
            userId: user._id,
            fileType: fileType,
          });
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: requestBody,
          };
  
          const apiResponse = await fetch(
            `${backend_url}/api/download`,
            requestOptions
          );
  
          const result = await apiResponse.json();
  
          if (apiResponse.ok) {
            const link = document.createElement("a");
            link.href = fileLink;
            link.download = fileLink.split("/").pop(); // Extract filename from URL
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error("Error from API:", result);
          }
        } catch (error) {
          console.error("Error occurred:", error);
        }
        finally{
            setDownloadLoading((prevState) => ({ ...prevState, [buttonKey]: false }));
        }
      };
    const SimpleDownload = async (fileLink) => {
            const link = document.createElement("a");
            link.href = fileLink;
            link.download = fileLink.split("/").pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      };


      console.log("from",type)

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 overflow-auto flex justify-center items-start pt-10 z-[9999999999999]">
            {
               loc.pathname==="/user-dashboard" ?
                (
                    <div className="relative bg-[#141414] w-full max-w-3xl mt-[10%] rounded-lg shadow-lg p-6">
                        <button
                        onClick={()=>{
                            onClose()
                        }}
                        className="absolute top-4 right-4 text-white"
                    >
                        <IoMdClose />
                    </button>

                    <div className='flex flex-col w-full gap-5 items-center justify-between pt-6'>
                    <button 
                        onClick={() => SimpleDownload(beat.mp3FileLink)}
                        className={`py-3 px-4 bg-red-500 hover:scale-105  duration-500 rounded-md text-sm w-full text-white`}>
                        Download Mp3
                    </button>
                    <button 
                        onClick={() => SimpleDownload(beat.wavFileLink)}
                        className={`py-3 px-4 bg-red-500 hover:scale-105  duration-500 rounded-md text-sm w-full text-white`}>
                        Download Wav
                    </button>
                    <button 
                        onClick={() => SimpleDownload(beat.zipFileLink)}
                        className={`py-3 px-4 bg-red-500 hover:scale-105 duration-500 rounded-md text-sm w-full text-white`}>
                        Download Stems
                    </button>

                    </div>

                    </div>
//WHOLE MODAL SETTINGS
                ) : (
                    <div className="relative bg-[#141414] w-full max-w-6xl rounded-lg shadow-lg p-6">
                    <button
                        onClick={()=>{
                            onClose()
                        }}
                        className="absolute top-2 right-2 text-white"
                    >
                        <IoMdClose />
                    </button>
                    <div className="text-center text-[30px] font-bold mb-4 text-white">
                            {beat.beatTitle}
                    </div>  
                    <div className="lg:flex justify-center lg:space-y-0 space-y-4 gap-5 items-stretch"> 
                    
                      {
                        ((user?.userType==="invited" && user?.Credits>0) || (user?.Credits>0 && user?.userType!=="vipUser") || (isAllow && user.userType !=="vipUser")) && (
                            <div className='bg-[#141414] lg:w-[25vw] text-white flex gap-5 flex-col items-center justify-center rounded-lg shadow-lg p-6'>
                                {
                                    loading ? (
                                          <img src={spinner} />
                                    ) :(
                                        <>
                                          <div>
                                      {
                                         isAllow ? (
                                        <>
                                            <h2 className="text-2xl font-semibold mb-2">You have unlocked this beat</h2>
                                            <p>You can download mp3 or wav</p>
                                            <p>(wait a few seconds for download)</p>
                                            </>
                                        ): (
                                            <>
                                            <h2 className="text-2xl font-semibold mb-2">Available Credits: {user.Credits}</h2>
                                            <p>You can use 1 credit to permanently unlock this beat and download mp3 and wav </p>
                                            
                                            </>
                                        )
                                    }
                                    </div>
                                
                                <div className='flex flex-col items-center gap-5 w-[100%]'>
                                <button 
                                disabled = {downloadLoading['btn1']}
                                        onClick={() => handleDownload(beat.mp3FileLink,"mp3",'btn1')}
                                        className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                        {downloadLoading['btn1'] ? "Downloading..." : "Download Mp3"}
                                    </button>
                                    <button 
                                    disabled={downloadLoading['btn2']}
                                        onClick={() => handleDownload(beat.wavFileLink,'wav','btn2')}
                                        className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                        {downloadLoading['btn2'] ? "Downloading..." : "Download Wav"}
                                    </button>
                                </div>
                                        </>
                                    )
                                }
                              
    
                            </div>
                        )
                      }
                      {
                        (user?.userType==="vipUser") && (
                            <div className='bg-[#141414] lg:w-[25vw] text-white flex gap-5 flex-col items-center justify-center rounded-lg shadow-lg p-6'>
                              <h2 className="text-2xl font-semibold mb-2">You are a VIP Member</h2>
                              <p>You can download mp3 and wav</p>
                              <p>(wait a few seconds for download)</p>
                              <div className='flex flex-col items-center gap-5 w-[100%]'>
                              <button 
                                    onClick={() => handleDownloadBeat(beat.mp3FileLink,'mp3','btn3')}
                                    className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                      {downloadLoading['btn3'] ? "Downloading..." : "Download Mp3"}
                                </button>
                                <button 
                                    onClick={() => handleDownloadBeat(beat.wavFileLink,'wav','btn4')}
                                    className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                      {downloadLoading['btn4'] ? "Downloading..." : "Download Wav"}
                                </button>
                              </div>
    
                            </div>
                        )
                      }
                      {
                        (user?.isStemsAvailable===true) && (
                            <div className='bg-[#141414] lg:w-[25vw] text-white flex gap-5 flex-col items-center justify-center rounded-lg shadow-lg p-6'>
                              <h2 className="text-2xl font-semibold mb-2">You have Stems Available</h2>
                              <p>You can alse download Stem file</p>
                              <div className='flex flex-col items-center gap-5 w-[100%]'>
                              <button 
                                    onClick={() => handleDownloadBeat(beat.zipFileLink,'zip','btn5')}
                                    className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                    {downloadLoading['btn5'] ? "Downloading..." : "Download Stems"}

                                </button>
                              </div>
    
                            </div>
                        )
                      }
                        {/* VIP Plan */}
                        {
                            user.userType !== "vipUser" && (
                                <div className="bg-[#141414] lg:w-[25vw] text-white flex flex-col justify-between rounded-lg shadow-lg p-6 border-2 border-yellow-600">
                                <div className="flex flex-col items-center">
                                    <h2 className="text-2xl font-semibold mb-2">VIP </h2>
                                    <h2 className="text-2xl font-normal mb-2">(mp3/wav)</h2>
                                    <div className="border-b-4 border-[#FB8500] w-8 mb-4"></div>
                                    <div className="py-4">
                                        <p className="text-4xl font-bold">$99</p>
                                    </div>
                                </div>
                                <div className="py-5">
                                    <p className="text-lg text-[#A9A9AA]">Become a VIP and get this beat +</p>
                                </div>
                                <ul className="flex flex-col gap-3 py-2">
                                    <li className="flex items-start gap-3 ">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>ALL 300+ VIP BEATS upfront</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>First access to ALL future beats</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>Keep 100% royalties/profits to every track, release anywhere</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>Non-exclusive licences</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>MP3 and HQ WAV to every beat</span>
                                    </li>
                                </ul>
                                <div className="pt-9">
                                    <button 
                                        className="py-3 px-4 bg-yellow-600 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white"
                                        onClick={() => HandlePurchasePlan('vipPlan')}
                                    >
                                        Become VIP
                                    </button>
                                </div>
                            </div>
                            )
                        }
                       
                       
    
                        {/* Stems Plan */}
                        {
                            loading ? (
                                <img src={spinner} />
                            ) : (
                                
                                      isAllowStems ? (
                                <div className='flex items-center justify-center gap-5 flex-col'>
                                    <p>You can download stems for this Beat</p>
                                    <button 
                                    onClick={() => handleDownloadBeat(beat.zipFileLink,'zip','btn6')}
                                    className={`py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white`}>
                                    {downloadLoading['btn6'] ? "Downloading..." : "Download Stems"}

                                  </button>
                                </div>
                            ) : (
                                <div className="bg-[#141414] lg:w-[25vw] text-white flex flex-col justify-between rounded-lg shadow-lg p-6 border border-red-500">
                                <div className="flex flex-col items-center">
                                    <h2 className="text-2xl font-semibold mb-2">Stems</h2>
                                    <div className="border-b-4 border-[#FB8500] w-8 mb-4"></div>
                                    <div className="py-4">
                                        <p className="text-4xl font-bold">$25</p>
                                    </div>
                                </div>
                                <div className="py-5">
                                    <p className="text-lg text-[#A9A9AA]">What You’ll Get</p>
                                </div>
                                <ul className="flex flex-col gap-3 py-2">
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>HQ WAV Tracked-out stems</span>
                                        
                                    </li>
                                </ul>
                                <ul className="flex flex-col gap-3 py-2">
                                    <li className="flex items-start gap-3">
                                        <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                        <span>Change structure of the beat however you like, speed up, slow down</span>
                                    </li>
                                </ul>
                                <div className="pt-9 flex flex-col items-center ">
                                {
                                    beat.zipFileLink && (
                                        <div className='text-[#FF3E41] text-sm cursor-pointer transform translate-y+15' onClick={isInCart ? handleRemoveFromCart : handleAddToCart}>
                                            {isInCart ? 'Remove From Cart' : 'Add to Cart'}
                                        </div>
                                    )
                                }
                                    <button 
                                        className={`py-3 px-4  ${beat.zipFileLink ? " bg-red-500" : "border border-red-500"} hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white `}
                                        onClick={() => HandlePurchasePlan('stems')}
                                        disabled={!beat.zipFileLink}
                                    >
                                        {beat.zipFileLink ? "Purchase Stems" : "Stems are not available for this beat, sorry"}
                                    </button>
                                    

                                </div>
                            </div>
    
                            )
                            )
                        }
    
                      
                      
    
                        {/* Exclusive Plan */}
                        <div className="bg-[#141414] lg:w-[25vw] text-white flex flex-col justify-between rounded-lg shadow-lg p-6 border border-red-500">
                            <div className="flex flex-col items-center">
                                <h2 className="text-2xl font-semibold mb-2">Exclusive</h2>
                                <div className="border-b-4 border-[#FB8500] w-8 mb-4"></div>
                                <div className="py-4">
                                    <p className="text-4xl font-bold">{beat.price}$</p>
                                </div>
                            </div>
                            <div className="py-5">
                                <p className="text-lg text-[#A9A9AA]">What You’ll Get</p>
                            </div>
                            <ul className="flex flex-col gap-3 py-2">
                                <li className="flex items-start gap-3">
                                    <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                    <span>Exclusive Rights + WAV Stems</span>
                                </li>
                            </ul>
                            <ul className="flex flex-col gap-3 py-2">
                                <li className="flex items-start gap-3">
                                    <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                    <span>Beat will be taken down from public catalog, only you will have access to it</span>
                                </li>
                            </ul>
                            <ul className="flex flex-col gap-3 py-2">
                                <li className="flex items-start gap-3">
                                    <img src={boxcircle} alt="checkmark" className="flex-shrink-0 mb-1" />
                                    <span>Keep 100% royalties and profits, release anywhere</span>
                                </li>
                            </ul>
                            
                            <div className="pt-9">
                                <button 
                                    className="py-3 px-4 bg-red-500 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white"
                                    onClick={() => HandlePurchasePlan('exclusivePlan')}
                                >
                                    Purchase Exclusive
                                </button>
                            </div>
                        </div>
                 
    
                    </div>
                </div>

                )
            }
          
        </div>
    );
};

export default PricingModel;
